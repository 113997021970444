import React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import QuantexLogo from "../../assets/images/quantex-logo.svg";
import "../../App.css";

function Bottom() {
  const isPwa = window.matchMedia("(display-mode: standalone)");
  const { pathname } = useLocation();
  const navigate = useHistory();

  const bottomNav = [
    { icon: "ion ion-md-home", label: "Home", path: "/" },
    { icon: "ion ion-ios-list-box", label: "Markets", path: "/dashboard" },
    // { icon: "ion ion-ios-paper", label: "Trade", path: "/trade" },
    {
      icon: "ion ion-ios-paper",
      label: "Trade",
      path: "https://trade.myquantex.com/trade/view/btc_usdt/",
    },
    { icon: " ion ion-md-sync", label: "Swap", path: "/swap/BTC/ETH/0.01" },
    {
      icon: " ion ion-md-wallet",
      label: "Wallet",
      path: "https://trade.myquantex.com/balance",
    },
  ];
  return (
    <>
      {isPwa?.matches ? (
        <div className="pwa-bottom-section">
          <div className="bottom-nav-wrapper">
            {bottomNav.map((nav) => {
              return (
                <div
                  className="nav-contr"
                  onClick={() => {
                    if (nav.path.startsWith("http")) {
                      // For external links
                      window.location.href = nav.path;
                    } else {
                      // For internal routes
                      navigate.push(nav.path);
                    }
                  }}
                >
                  <div className="nav-icon-wrapper">
                    <i
                      className={`${nav.icon} ${
                        pathname === nav.path ? "active" : ""
                      }`}
                    />
                  </div>
                  <p
                    className={`nav-label  ${
                      pathname === nav.path ? "active" : ""
                    }`}
                  >
                    {nav.label}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div class="bottom section-padding">
          <div class="container">
            <div class="row">
              <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                <div class="bottom-widget">
                  <h4 class="widget-title">Company</h4>
                  <ul>
                    <li>
                      <Link to={"about"} className="Hover-text">
                        About
                      </Link>
                    </li>
                    <li>
                      <a
                        className="Hover-text"
                        href="https://blog.myquantex.com"
                      >
                        Blog
                      </a>
                    </li>
                    <li>
                      <Link className="Hover-text" to={"security"}>
                        Security
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                <div class="bottom-widget">
                  <h4 class="widget-title">Support</h4>
                  <ul>
                    <li>
                      <Link className="Hover-text" to={"help"}>
                        Help
                      </Link>
                    </li>
                    <li>
                      <Link className="Hover-text" to={"contact"}>
                        Contact
                      </Link>
                    </li>
                    <li>
                      <Link className="Hover-text" to={"faq"}>
                        FAQ
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                <div class="bottom-widget">
                  <h4 class="widget-title">Products</h4>
                  <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <ul>
                        <li>
                          <Link
                            className="Hover-text"
                            to={"/swap/BTC/ETH/0.01"}
                          >
                            Swap
                          </Link>
                        </li>
                        <li>
                          <a
                            className="Hover-text"
                            href="https://trade.myquantex.com/"
                          >
                            Trade
                          </a>
                        </li>
                        <li>
                          <a
                            className="Hover-text"
                            href="https://etherscan.io/token/0x577cc8560636bf585e1bb1bb2e281cedd4cd84ea"
                          >
                            Quantex Coin QTX
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 offset-xl-2 offset-lg-2 col-md-6 col-sm-6">
                <div class="bottom-logo">
                  <img src={QuantexLogo} alt="Quantex Logo" />
                  <div class="footer-social text-center">
                    <ul>
                      <li>
                        <Link
                          to={{
                            pathname: "https://twitter.com/Quantex_Global",
                          }}
                          class="fa fa-twitter"
                          target="_blank"
                        ></Link>
                      </li>
                      <li>
                        <Link
                          to={{
                            pathname: "https://t.me/Quantex_Global",
                          }}
                          class="fa fa-telegram"
                          target="_blank"
                        ></Link>
                      </li>
                      <li>
                        <Link
                          to={{
                            pathname: "https://www.reddit.com/r/Quantex/",
                          }}
                          class="fa fa-reddit"
                          target="_blank"
                        ></Link>
                      </li>
                      <li>
                        <Link
                          to={{
                            pathname:
                              "https://www.youtube.com/channel/UCty9J9RF5OGSsdtrmFNzAfQ",
                          }}
                          class="fa fa-youtube"
                          target="_blank"
                        ></Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Bottom;

import {   Col, Container, Row } from 'react-bootstrap';

// images
import desktop from '../../../assets/images/AWS-cloud-security-below-banner.png';

const Hero8 = () => {
    return (
        <section className="position-relative overflow-hidden hero-7 pt-6 pb-4">
            <Container>
                <Row className="align-items-center text-center text-sm-start">
                    <Col lg={6}>
                        <h1 className="hero-title clrwhite">
                        Keeping your Funds Secure is our{' '}
                            <span className="">Top Priority</span>
                        </h1>
                        <p className="fs-16 mt-3 fw-medium clrwhite">
                            With the help of AWS, Quantex has built a state of the art, modern security system.
                        </p>
                    </Col>
                    <Col lg={{ span: 5, offset: 1 }} className="hero-right">
                        <div className="img-container" data-aos="fade-left" data-aos-duration="600">
                            <img src={desktop} alt="desktop" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Hero8;

import { Col, Container, Row } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';

// images
import desktop1 from '../../../assets/images/AWS shared_Responsibility_Model.png';
import desktop from '../../../assets/images/login-bg.png';

const Feature1 = () => {
    return (
        <section className="position-relative overflow-hidden py-6 features-3">
            <Container>
                <Row className="align-items-center">
                    <Col lg={6}>
                        <div className="mb-lg-0 mb-4" data-aos="fade-right" data-aos-duration="600">
                            <h1 className="display-5 fw-medium mb-2 clrwhite">Our Security Measures</h1>

                            <div className="d-flex mb-2 align-items-center">
                                <div className="list-inline-item me-3 flex-shrink-0">
                                    <span className="bg-soft-primary avatar avatar-sm rounded-lg icon icon-with-bg icon-xs text-primary" style={{width:'40px', height:'40px'}}>
                                        <FeatherIcon icon="settings" className="icon-dual-primary" />
                                    </span>
                                </div>
                                <div className="fw-medium fs-16 align-self-center flex-grow-1">
                                  <h3 className='clrwhite'>Multi-Factor Authentication</h3>
                                  <p className='clrwhite'>MFA is required for all important processes like logging in and withdrawals.</p>
                                </div>
                            </div>

                            <div className="d-flex mb-2 align-items-center">
                                <div className="list-inline-item me-3 flex-shrink-0">
                                    <span className="bg-soft-primary avatar avatar-sm rounded-lg icon icon-with-bg icon-xs text-primary" style={{width:'40px', height:'40px'}}>
                                        <FeatherIcon icon="users" className="icon-dual-primary" />
                                    </span>
                                </div>
                                <div className="fw-medium fs-16 align-self-center flex-grow-1">
                                  <h3 className='clrwhite'>Authentication and Authorization</h3>
                                  <p className='clrwhite' >Quantex will use AWS Cognito in conjuction with Oauth2.0, to authenticate API’s & back-end resources.</p>
                                </div>
                            </div>
                            
                            <div className="d-flex mb-2 align-items-center">
                                <div className="list-inline-item me-3 flex-shrink-0">
                                    <span className="bg-soft-primary avatar avatar-sm rounded-lg icon icon-with-bg icon-xs text-primary" style={{width:'40px', height:'40px'}}>
                                        <FeatherIcon icon="command" className="icon-dual-primary" />
                                    </span>
                                </div>
                                <div className="fw-medium fs-16 align-self-center flex-grow-1">
                                    <h3 className='clrwhite'>Encrypted storage</h3>
                                    <p className='clrwhite'>AWS Key Management Service (KMS) will be used to encrypt data in transit and at rest. Access to the encryption and decryption keys are securely monitored and maintained. </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={{ span: 5, offset: 1 }}>
                        <img
                            src={desktop1}
                            alt="desktop1"
                            className="img-fluid"
                            data-aos="fade-left"
                            data-aos-duration="700"
                        />
                    </Col>
                </Row>

                <Row className="align-items-center pt-9">
                    <Col lg={6}>
                        <div
                            className="p-2 rounded"
                            data-aos="fade-right"
                            data-aos-duration="600"
                        >
                            <img src={desktop} alt="desktop" className="img-fluid" />
                        </div>
                    </Col>
                    <Col lg={{ span: 5, offset: 1 }}>
                        <div className="mt-4 mt-lg-0" data-aos="fade-left" data-aos-duration="700">
                            <h1 className="display-5 fw-medium mb-2 clrwhite">Compliance</h1>
                            <h3 className="mb-2 clrwhite">Cryptocurrency Security Standard (CCSS)</h3>
                            <p className="fw-medium fs-16 clrwhite">Quantex will follow the Cryptocurrency Security Standard (CCSS) as a blueprint for building and operating the exchange. CCSS is currently the go-to security standard for any organization that handles and manages crypto wallets as part of its business logic. Unlike other cyber security standards, the CCSS was purpose built to secure cryptocurrency exchanges.</p>

                            <h3 className="mb-2 clrwhite">ISO/IEC 27001:2013</h3>
                            <p className="fw-medium fs-16 clrwhite">Quantex will also work to achieve the ISO 27001:2013 standard in the near future. ISO 27001 is and has been considered the international gold standard for cyber security.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Feature1;

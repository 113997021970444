import { useEffect } from 'react';

const Contact = () => {
    useEffect(() => {
        window.location.href = 'https://help.myquantex.com/contact';
    }, []);

    return null;
};

export default Contact;
